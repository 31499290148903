
import { defineComponent, ref, watch, onMounted } from "vue";
import { number, object, string } from "yup";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import { VueCookieNext } from "vue-cookie-next";
import { ElNotification } from "element-plus";
import { useBus } from "@/bus.ts";
import { DrawerComponent } from "@/assets/ts/components";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "PartnerModal",
  props: {
    data: { type: Object },
    loadData: { type: Function },
  },
  setup(props) {
    const { bus } = useBus();
    const iaPartnerOptions = ref([]);
    const formData = ref({
      id: "",
      updated_by: "",
      created_by: VueCookieNext.getCookie("_user_id"),
      name: "",
      email: "",
      designation: "",
      ia_partner_id: "",
      role: "",
    });

    watch(
        () => props.data,
        (newData) => {
          if (newData) {
            formData.value = {
              ...formData.value,
              ...newData,
            };

            formData.value.name = newData.employee.name;
            formData.value.designation = newData.employee.designation;
            formData.value.role = newData.employee.role_id;
          }
        }
    );

    const schema = object().shape({
      name: string().required("Name is required"),
      email: string().email().required("Email is required"),
    });

    const errors = ref({});

    const save = async () => {
      try {
        if (formData.value.id) {
          formData.value.updated_by = VueCookieNext.getCookie("_user_id");
        }
        await schema.validate(formData.value, { abortEarly: false });
        ApiService.post(apiEndpoint.data().VUE_APP_USER_STORE, formData.value)
          .then((response) => {
            if (response.data.status == "success") {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.data,
                type: "success",
              });
              DrawerComponent.hideAll();
              bus.emit("loadData", true);
            } else {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.msg,
                type: "error",
              });
            }
          })
          .catch(({ response }) => {
            console.log(response);
          });

        formData.value = {
          id: "",
          updated_by: "",
          created_by: VueCookieNext.getCookie("_user_id"),
          name: "",
          email: "",
          designation: "",
          ia_partner_id: "",
          role: "",
        };
        errors.value = {};
      } catch (validationErrors) {
        errors.value = validationErrors.inner.reduce((acc, error) => {
          acc[error.path] = error.message;
          return acc;
        }, {});
      }
    };

    const getIaPartnerOptions = async () => {
      try {

        const id = VueCookieNext.getCookie("_ia_partner_id") !== "null"
            ? VueCookieNext.getCookie("_ia_partner_id")
            : 0;

        const response = await ApiService.post(
            apiEndpoint.data().VUE_APP_IAPARTNER_LIST,
            {
              id : id
            }
        );

        iaPartnerOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.name,
        }));

      } catch (error) {
        console.error("Error fetching parent output options:", error);
      }
    };

    onMounted(getIaPartnerOptions);

    return { formData, errors, save, iaPartnerOptions };
  },
});
