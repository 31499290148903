
import { defineComponent, ref, onMounted, watch } from "vue";
import UserModal from "@/components/modals/forms/user/UserModal.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import { ElNotification } from "element-plus";
import { useBus } from "@/bus.ts";
import { DrawerComponent } from "@/assets/ts/components";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "IaPartnerList",
  components: {
    UserModal,
  },
  setup() {
    const { bus } = useBus();
    const editData = ref(null);
    const userList = ref([]);
    const trash = ref(false);

    const create = (data) => {
      editData.value = data ? data : null;
    };

    const edit = (data) => {
      editData.value = data;
    };

    const show = (item) => {
      // Add logic for showing item
    };

    const deleteOutput = async (id) => {
      Swal.fire({
        title: trash.value ? "Are you sure you want to restore it?" : "Are you sure you want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            console.log(apiEndpoint.data().VUE_APP_OUTPUT_DELETE);
            const response = await ApiService.post(
                apiEndpoint.data().VUE_APP_IAPARTNER_DELETE,
                {
                  id: id,
                }
            );

            if (response.data.status == "success") {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.data,
                type: "success",
              });
              loadData();
            } else {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.message,
                type: "error",
              });
            }
          } catch (error) {
            console.error("Error deleting output:", error);
            ElNotification({
              dangerouslyUseHTMLString: true,
              message: "Error deleting output",
              type: "error",
            });
          }
        }
      });
    };

    const loadData = async () => {
      try {
        console.log('url :' + apiEndpoint.data().VUE_APP_USER_LIST);

        const response = await ApiService.post(
            apiEndpoint.data().VUE_APP_USER_LIST,
            {
              trash: trash.value ? 1 : 0,
            }
        );
        userList.value = response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const loadTrash = () => {
      trash.value = !trash.value;
    };

    watch(trash, (newVal) => {
      loadData();
    });

    onMounted(async () => {
      DrawerComponent.bootstrap();
      DrawerComponent.updateAll();

      loadData();

      bus.on("loadData", async () => {
        loadData();
      });
    });

    return { editData, userList, trash, loadTrash, create, edit, show, deleteOutput };
  },
});
